@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;1,300&family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;1,300&family=Questrial&family=Roboto:wght@500&display=swap');


.background {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    transform-origin: bottom;
    transform: scale(1, 110%);
    opacity: 85%;
}

.section {
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0);
}

.buttons_top {
    display: flex;
    flex-direction: row;
    gap: 39px;
    align-items: center;
    justify-content: center;
    /* z-index: 2; */
    /* width: 100px; */
    /* width: 50%; */
}

.buttons_top .btn1 {
    color: white;
    width: 13vw;
    height: 7vh;
    align-items: center;
    justify-content: center;
    border-radius: 26.857px;
    border: 1.492px solid #FFF;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1.194px;
    line-height: normal;
    background-color: transparent;
    z-index: 2;
    font-size: 24px;
    font-weight: 900;
    backdrop-filter: blur(40px);
    box-shadow: 0px 0px 10px #e6f0ef;
}

.buttons_top .btn1:hover {
    cursor: pointer;
}

.buttons_top .btn2 {
    padding: 0px 25px;
    color: #000000;
    z-index: 2;
    min-width: 13vw;
    width: auto;
    min-height: 7vh;
    height: auto;
    border-radius: 26.857px;
    border-color: black;
    border-style: solid;
    font-family: 'Roboto', sans-serif;
    line-height: auto;
    background-color: #cecece54;
    font-size: 24px;
    font-weight: 900;
    z-index: 2;
    cursor: pointer;
    backdrop-filter: blur(18px);
    box-shadow: 0px 0px 10px #323232;
}

.middlepart {
    display: flex;
    /* flex-direction: Z; */
    margin: 0;
    height: max-content;

}

.box1 {
    padding: 50px 70.52px 130.56px 70.55px;
    display: flex;
    flex-direction: column;
    gap: 150px;
    width: 50%;
    align-items: center;
    justify-content: center;


    /* height: 125vh; */
}

.upper_box1 {
    display: flex;
    width: 30vw;
    height: 60vh;
    margin-top: 10rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ch {
    width: 250.453px;
    height: 142.67px;
    flex-shrink: 0;
    /* align-items: center;*/
    text-align: center;
    margin-top: 50px;
    font-family: Questrial;
    color: #FFF;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -15px;
    line-height: normal;
}

.c {
    width: 47.872px;
    height: 109.67px;
    font-size: 90.955px;

}

.h {
    width: 150.559px;
    height: 128.819px;
    font-size: 130.94px;
}

.pink {
    color: #F74D79;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.6px;
    /* margin-left: 138px; */
    margin-top: 5px;
}

.connect_container {
    display: flex;
    flex-direction: column;
    /* margin-left: 78px; */
    margin-top: 30px;
    width: 445px;
    height: 372px;
    border-radius: 8.36px;
    border: 1.045px solid #414141;
    background: #2F2F2F;
    color: #FFF;

}

.item {
    position: relative;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    width: 445px;
    height: 62px;
    padding: 20px 211px 0px 35px;
    margin-left: 35px;
}

.item:hover {
    cursor: pointer;
}

.addline {
    width: 445.01px;
    height: 0.25px;
    background: #C6C6C6;
    /* z-index: -1; */
}

.cont {

    /* margin-left: 78px; */
    margin-top: 20px;
    display: flex;
    width: 445px;
    height: 61px;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #2F2F2F;
    color: #FFF;
    text-align: justify;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    cursor: pointer;
}

.lowerbox {
    width: 30vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    margin-top: 10rem;
}

.lowerbox2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 5px;
    margin-top: 10rem;
}

.ch2 {
    width: 250.453px;
    height: 142.67px;
    flex-shrink: 0;
    align-items: center;
    text-align: center;
    position: relative;
    /* top: -10px; */

    margin-top: 5px;
    font-family: Questrial;
    color: #FFF;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -15px;
    line-height: normal;
}



.connect {
    color: #F74D79;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.6px;
    height: 38px;


}

.frame65 {
    width: 25vw;
    min-height: 100px;
    height: auto;
    max-height: 200px;
    border-radius: 8.36px;
    border: 1.045px solid #414141;
    background: #2F2F2F;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
}

.text {
    height: auto;
    color: #A0A0A0;
    text-align: center;
    font-family: Questrial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.96px;
}

.frame67 input {
    display: flex;
    width: 445px;
    height: 61px;

    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #2F2F2F;
    /* margin-left: 91px; */
    margin-top: 20px;

    color: #6B6B6B;
    border: 1.045px solid #414141;
    text-align: justify;
    border-radius: 8px;
    font-family: Roboto;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 1px;
}

::placeholder {
    text-align: center;
}


.frame66 {
    /* display: flex; */
    width: 445px;
    height: 61px;
    padding: 10px 0px;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 10px;
    border-radius: 8px;
    background: #F74D79;
    /* margin-left: 91px; */
    margin-top: 20px;

    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
}



.frame68 {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    /* margin-left: 108px; */
    width: 418px;
    height: 16px;
    margin-top: 25px;
}

.line {
    width: 147.014px;
    height: 0.5px;
    background: #9F9F9F;
}

.or_continue {
    color: #9F9F9F;
    text-align: justify;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    /* margin-left: 261.01px; */
}

.frame71 {
    display: inline-flex;
    align-items: flex-start;
    gap: 18px;
    width: 446px;
    height: 40px;
    /* margin-left: 91px; */
    margin-top: 25px;
}

.frame69 {
    display: flex;
    gap: 12px;
    width: 214px;
    height: 40px;
    padding: 10px 67.8px 10.8px 59px;
    align-items: center;
    border-radius: 8.36px;
    border: 1.045px solid #414141;
    background: #2F2F2F;

    color: #6B6B6B;
    text-align: justify;
    font-family: Roboto;
    font-size: 19px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.8px;
    margin-left: 10px;

}

.google_logo {
    width: 19.2px;
    height: 19.2px;
}



.frame70 {
    display: flex;
    width: 214px;
    height: 40px;
    gap: 12px;
    padding: 10.5px 49px 10.5px 89px;
    align-items: center;
    background-color: #F74D79;
    border-radius: 8.36px;
    border: 1.045px solid #414141;

    color: #FFF;
    text-align: justify;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.8px;
    align-items: center;

}

.insta_logo {
    width: 20px;
    height: 20px;
    margin-left: -50px;

}

.box2 {

    padding: 100px 57.52px 105.56px 57.55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100.9px;
    background: #383838;
    width: 50%;
    /* position: relative;  */
    /* margin: 30px; */



}

.sidepart {
    width: 40vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
    gap: 50.6px;
}


.h1,
.h2,
.h3 {
    display: flex;
    gap: 12.6px;
    flex-direction: row;
    align-items: flex-start;
    width: 95%;

}

.text1 {
    width: 424px;
    height: 36px;
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.44px;

}

.text2,
.text4,
.text6 {
    flex-shrink: 0;
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.9px;
}

.text3 {
    width: 424px;
    height: 36px;
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.44px;
}


.text5 {
    width: 424px;
    height: 36px;
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.44px;
}

.sidepart2 {
    width: 40vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
    gap: 50.6px;
    /* margin-top: 100px; */
    margin-left: -10px;
    /* display: none; */
}

@media only screen and (min-width: 320px) and (max-width:1023px) {

    .section {
        width: 100vw;
        height: 60vh;

    }

    .buttons_top {
        flex-direction: column;

    }



    .buttons_top .btn1,
    .buttons_top .btn2 {
        width: 40vw;
        font-size: 20px;
        min-height: 8vh;
        max-height: 100px;
        align-items: center;
    }

    .box1 {
        width: 100vw;
        height: max-content;
        padding: 20px;
        gap: 200px;

    }

    .middlepart {
        flex-direction: column;
    }

    .upper_box1 {
        width: 90vw;
        align-items: center;
        justify-content: center;
        height: 80vh;
        margin-top: 20px;
    }

    .ch {
        width: 17hw;
        height: 10vh;
        margin-top: 60px;
        font-family: Questrial;
        color: #FFF;
        font-weight: 300;
    }

    .c {
        width: 10vw;
        font-size: 65.955px;
    }

    .h {
        width: 10vw;
        font-size: 95.94px;
    }

    .pink {
        font-size: 25px;
        /* margin-left: 50px; */
        width: 80vw;
        margin-top: 20px;
        padding-top: 5px;
    }

    .connect_container {

        margin-left: 0px;
        margin-top: 30px;
        width: 80vw;
        height: 300px;
        justify-content: center;
        /* padding: 10px; */

    }

    .item {
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        width: 80vw;
        height: 50px;
        padding: 10px 151px 0px 25px;
        margin-left: 20px;

    }

    .addline {
        width: 100%;
    }


    .cont {

        /* margin-left: 40px; */
        width: 80vw;
        height: 50px;
    }

    .lowerbox {
        width: 80vw;
        /* height: 80vh; */
        /* max-height: fit-content; */
        height: 90vh;
        gap: 5px;
    }

    .ch2 {
        width: 17hw;
        height: auto;
    }

    .connect {
        font-size: 25px;
        height: auto;
        margin-top: 0px !important;
        width: 80vw;
        margin-top: 15px;

    }


    .frame65 {

        width: 90vw;
        /* height:15vh; */
        /* height: fit-content; */
        /* padding: 10px 20px 20px 20px; */
        /* margin-left: 28px; */
    }

    .text {
        font-size: 14px;
        padding: 10px;
    }

    .frame67 input {
        display: flex;
        width: 320px;
        height: 45px;
        /* margin-left: 30px; */
    }


    .frame66 {
        width: 85vw;
        /* height: 16vw;x */
        /* margin-left: 30px; */
        font-size: 18px;
    }

    .frame68 {

        gap: 5px;
        /* margin-left: -20px; */
        height: 14px;
        margin-top: 25px;
        width: 100vw;

    }

    .line {
        width: 80px;
        height: 0.5px;
    }

    .or_continue {
        font-size: 14px;
        font-weight: 500;

    }

    .frame71 {

        gap: 10px;
        width: fit-content;
        /* margin-left: 30px; */
    }

    .frame69 {
        width: 40vw;
        height: 40px;
        padding: 5px 50.8px 5.8px 40px;
        /* margin-left: -5px; */
    }

    .google_logo {
        width: 15.2px;
        height: 15.2px;
        margin-left: -10px;
    }

    .cont {
        font-size: 17px;
    }

    .frame70 {
        width: 40vw;
        padding: 5px 50.8px 5.8px 60px;
    }

    .insta_logo {
        width: 15px;
        height: 15px;
        margin-left: -50px;

    }


    .box2 {
        width: 100vw;
        max-height: max-content;
        padding: 0px;
    }

    .sidepart {
        width: 80vw;
        flex-wrap: wrap;
        max-height: fit-content;
        height: 100vh;
    }



    .h1,
    .h2,
    .h3 {
        width: 100vw;
        gap: 5px;
        flex-direction: row;
        max-width: 80vw;
        word-wrap: break-word;

    }

    .logo1,
    .logo2,
    .logo3 {
        width: 28px;
        height: 28px;
    }

    .side_text {
        width: 75vw;
        gap: 5px;
    }

    .text1,
    .text3,
    .text5 {
        font-size: 20px;
        font-weight: 500;
        width: 80vw;


    }

    .text2,
    .text4,
    .text6 {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.6px;
        /* width: 90vw; */

        word-wrap: break-word;
    }


    .sidepart2 {
        display: none;
    }




    .lastpart {
        display: none;
    }



}


@media only screen and (min-width: 768px) and (max-width:1023px) {

    .connect_container {

        margin-left: 0px;
        margin-top: 30px;
        width: 445px;
        height: 372px;
        border-radius: 8.36px;
        border: 1.045px solid #414141;
        background: #2F2F2F;
        color: #FFF;

    }


    .item {
        position: relative;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1px;
        width: 445px;
        height: 62px;
        padding: 20px 211px 0px 35px;
        /* margin-left: 35px; */

    }

    .addline {
        width: 445.01px;
        height: 0.25px;
        background: #C6C6C6;
        /* z-index: -1; */
    }

    .cont {

        margin-left: 0px;
        margin-top: 20px;
        display: flex;
        width: 445px;
        height: 61px;
        padding: 10px 0px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #2F2F2F;
        color: #FFF;
        text-align: justify;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1px;
    }

    .pink {
        color: #F74D79;
        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.6px;
        margin-left: 0px;
        margin-top: 5px;
        width: fit-content;

    }

    .connect {
        color: #F74D79;
        font-family: Roboto;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.6px;
        height: 38px;
        margin-left: 0px;

    }


    .text {
        font-size: 16px;
        letter-spacing: 0.96px;

    }

    .frame67 input {
        display: flex;
        width: 445px;
        height: 61px;

        padding: 10px 0px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: #2F2F2F;
        margin-left: 0px;
        margin-top: 20px;

        color: #6B6B6B;
        border: 1.045px solid #414141;
        text-align: justify;
        border-radius: 8px;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1px;
    }

    ::placeholder {
        text-align: center;
    }

    .frame66 {
        display: flex;
        width: 445px;
        height: 61px;
        padding: 10px 0px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F74D79;
        margin-left: 0px;
        margin-top: 20px;

        color: #FFF;
        text-align: justify;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1px;
    }


    .frame68 {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        margin-left: 0px;
        width: 418px;
        height: 16px;
        margin-top: 25px;
    }

    .line {
        width: 147.014px;
        height: 0.5px;
        background: #9F9F9F;
    }

    .or_continue {
        color: #9F9F9F;
        text-align: justify;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.7px;
        margin-left: 0px;
    }

    .frame71 {
        display: inline-flex;
        align-items: flex-start;
        gap: 18px;
        width: 446px;
        height: 40px;
        margin-left: 0px;
        margin-top: 25px;
    }

    .frame69 {
        display: flex;
        gap: 12px;
        width: 214px;
        height: 40px;
        padding: 10px 67.8px 10.8px 59px;
        align-items: center;
        border-radius: 8.36px;
        border: 1.045px solid #414141;
        background: #2F2F2F;

        color: #6B6B6B;
        text-align: justify;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.8px;
        margin-left: 0px;

    }

    .google_logo {
        width: 19.2px;
        height: 19.2px;
    }



    .frame70 {
        display: flex;
        width: 214px;
        height: 40px;
        gap: 12px;
        padding: 10.5px 49px 10.5px 89px;
        align-items: center;
        background-color: #F74D79;
        border-radius: 8.36px;
        border: 1.045px solid #414141;

        color: #FFF;
        text-align: justify;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.8px;
        align-items: center;

    }

    .insta_logo {
        width: 20px;
        height: 20px;
        margin-left: -50px;

    }






}