.ssection {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10vh;
  align-items: center;
}

.section1 {
  gap: 25px;
  margin-top: -2vh !important;
}

.ssection .titleContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;
  gap: 20px;
}

.ssection .titleContainer .title1 {
  font-size: 45px;
  font-weight: 500;
  color: white;
  text-align: start;
  margin-bottom: 0px;
}

.ssection .titleContainer .title {
  font-size: 37px;
  font-weight: 500;
  color: white;
  text-align: start;
}

.ssection .titleContainer .subtitle {
  font-size: 20px;
  font-weight: 300;
  color: #b0b0b0;
  text-align: left;
  /* width: 100vw; */
}

.section1 .form {
  display: flex;
  flex-direction: row;
  gap: 30px;
  border-radius: 10.091px;
  border: 1.121px solid #414141;
  background: #2f2f2f;
  width: 50vw;
  height: 55vh;
  max-height: 600px;
  padding: 30px;
}

.section1 .editBanner {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid white; */
}

.section1 .editBanner .edit {
  color: white;
  margin: auto 0px;
  background-color: #232426;
  cursor: pointer
}

.section1 .right_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1vw 0;
}

.section1 .inputBox_profile {
  height: 150px;
  width: 150px;
  margin: 1.6vw 0;
  background-color: #3e3e3e;
  border: 1px solid #5e5e5e;
  border-radius: 50%;
  font-size: 0px;
  cursor: pointer;
}

.custom-file-upload {
  height: 150px;
  width: 150px;
  margin: 15px 0px;
  background-color: #3e3e3e;
  border: 1px solid #5e5e5e;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  color: #f74d79;
  font-family: Roboto;
  font-size: 20.75px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.238px;

}

.custom-file-upload:hover {
  background-color: #4e4e4e;
}

.section1 .inputBox {
  height: 25%;
  width: 100%;
  border-radius: 9px;
  border: 1.125px solid #5e5e5e;
  background: #343434;
  font-size: 18px;
  margin: 10px;
  color: #A0A0A0;
  padding: 0px 20px;
  font-family: 'Questrial', sans-serif;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: left;
}

.ssection .title {
  font-size: 28px;
  color: white;
  margin-bottom: 15px;
}

.section2 .form_radio {
  gap: 40px;
  border-radius: 10.091px;
  border: 1.121px solid #414141;
  background: #2f2f2f;
  width: 50vw;
  height: fit-content;
  padding: 30px;
  margin-top: 10px;
  /* align-items: center;  
   justify-content: center; */


}

.section2 .input_radio {
  border-radius: 10.091px;
  border: 1.121px solid #414141;
  background: #343434;
  font-size: 20.25;
  font-family: "Roboto";
  font-weight: "500";
  letter-spacing: 1.01;
  width: 100%;
  height: 5vh;
  padding: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.section2 .input_radio .label {
  color: #5E5E5E;
  font-size: 20.25;
  font-family: "Roboto";
  font-weight: 500;
  letter-spacing: 1.01;
  word-wrap: break-word;
}

.radio-input {
  appearance: none;
  background-color: #343434;
  width: 24px;
  height: 24px;
  border: 1px solid #5c5c5c;
  margin: 0;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.radio-input::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s transform ease-in-out;
  box-shadow: inset 6px 6px #F74D79;
}

.radio-input:checked {
  background: #f74d79;
  border-color: #f74d79;
}

.radio-input:checked::before {
  transform: scale(1);
}

.section2>div {
  width: 50%;
}

/* .section2 .inputBox {
  height: 25%;
  margin: 15px 0px;
  background-color: #343434;
  border: 1px solid #5e5e5e;
  border-radius: 8px;
} */
.section3 .boxes {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.section3 .title {
  font-size: 37px;
  font-weight: 700;
  color: white;
  text-align: start;
}

.section3 .left_boxes {
  display: flex;
  flex-direction: row;
  gap: 8%;
}

.section3 .inner_box {
  width: 40vw;
  height: 30vh;
  max-height: 300px;
  border-radius: 11px;
  background-color: #2f2f2f;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}

.section3 .inner_box .headers {
  color: white;
  font-size: 20.25px;
  font-family: Roboto;
  font-weight: 700;
  letter-spacing: 1.01;
  word-wrap: break-word;
}

.section3 .inner_box .details {
  color: white;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 100;
  letter-spacing: 0/84;
  word-wrap: break-word;
  /* margin-top: 20px;
  margin-bottom: 20px; */
}

.section3 .view {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #3c3c3c;
  border: none;
  padding: 20px 20px;
  border-radius: 5px;
  color: aliceblue;
  font-weight: 700;
  margin-top: 20px;
  color: #f74d79;
  cursor: pointer;
}

.section3 .view:hover {

  background-color: #eeeeee;


  color: #f74d79;
}

.section3 .right_boxes {
  display: flex;
  flex-direction: row;
  gap: 8%;
}

.section3 .subtitle {
  font-size: 18px;
  color: #a7a7a7;
  margin-bottom: 15px;
}

.section3 .inputBox {
  height: auto;
  min-height: 206px;
  min-width: 746px;
  margin: 15px 0px;
  background-color: #343434;
  border: 1px solid #5e5e5e;
  border-radius: 8px;
  font-size: 18px;
}

.section4>div {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 20px;
}

.section4 .fileupload {
  height: 67px;
  width: 763px;
}

.section3 .container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.section3 {
  padding-bottom: 6vh;
}

.section3 .container .save {
  height: 40px;
  width: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #3c3c3c;
  border: none;
  padding: 20px 20px;
  border-radius: 5px;
  color: aliceblue;
  font-weight: 700;
  color: #f74d79;
  font-size: 18px;
  cursor: pointer;
}

.section3 .container .save:hover {
  background-color: #ffffff;
  color: #f74d79;
}


/*****************************************************************************/

@media screen and (max-width: 768px) {
  .section3 .inner_box {
    width: 50vw;
    height: 35vh;
  }
}

@media screen and (max-width: 426px) {

  .ssection .titleContainer,
  .section1 .form,
  .section2 .form_radio {
    width: 85vw;
  }

  .ssection .titleContainer .title1 {
    font-size: 28px;
  }

  .ssection .titleContainer .title {
    font-size: 24px;
  }

  .ssection .titleContainer .subtitle {
    font-size: 16px;

  }

  .section2 .input_radio .label {

    font-size: 16px;

  }

  .section1 .inputBox {
    font-size: 16px;
  }

  .section2 .input_radio {
    font-size: 16px;
  }

  .section1 .form {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .section1 .form .left_form {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section1 .form .right_form .inputBox {
    height: 5vh;
  }


  .section2 .form_radio .input_radio {
    padding: 4%;
    height: 10vh;
    max-height: 65px
  }

  .section2 .form_radio .label {
    width: 70%;
  }


  .section3 .left_boxes,
  .section3 .right_boxes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  .section3 .inner_box {
    width: 100%;
    min-height: 28vh;
    height: auto;
  }

}