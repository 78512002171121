.settingsCard{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-between !important;
    gap: 0.5vw !important;
    border-radius: 10px !important;
    background-color: #2F2F2F !important;
    width: 11.5vw !important;
    height: 8vw !important;
    border: 1px solid #414141 !important;
    padding: 1vw !important;
}

.settingsCard .cardHeading{
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    text-align: center;
    line-height: 9px !important;
    letter-spacing: 0.05em !important;
    color: #FFFFFF !important;
}

.settingsCard .cardContent{

font-family: 'Roboto' !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 10px !important;
line-height: 14px !important;
text-align: center !important;
letter-spacing: 0.05em !important;

color: #FFFFFF !important;


}

.settingsCard .button{
    width:9vw;
    height: 1.5vw;
    padding: 0 !important;


background: #3C3C3C !important;
border-radius: 3px !important;
font-family: 'Roboto'!important;
font-style: normal !important;
font-weight: 900 !important;
font-size: 14px !important;
line-height: 8px !important;
text-transform: none !important;
letter-spacing: 0.05em !important;

color: #F74D79 !important;

&:hover {
    background-color: white !important;
}

}


/*******************************************************************/


@media screen and (max-width:1440px){
    .settingsCard{
        height: 10vw !important;
    }
}


@media screen and (max-width:1024px){
    .settingsCard{
        width: 11vw !important;
        height: 16vw !important;
    }

    .settingsCard .cardHeading{
        font-weight: 500 !important;
        font-size: 10px !important;
        line-height: 13px !important;
        letter-spacing: 0.05em !important;
    }

    .settingsCard .cardContent{
        font-weight: 400 !important;
        font-size: 9px !important;
        line-height: 14px !important;
    }

    .button{
        height: 2.5vw !important;
    }
}

@media screen and (max-width:768px){
    .settingsCard{
        width: 100% !important;
    }
}

@media screen and (max-width:375px){
    .settingsCard{
        width: 100% !important ;
        height: 10vh !important;
    }
    .cardHeading{
        font-size: 15px !important;
        padding-bottom: 0.5vh;
    }
    .cardContent{
        font-size: 10px !important;
    }
    .button{
        height: 5vw !important;
    }
}


