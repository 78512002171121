.footerContainer {
    /* margin-top: 5rem; */
    padding-top: 3rem;
    padding-left: 6rem;
    border-top: 3px solid white;
}

.footerContainer .row2 {
    display: flex;
    gap: 150px;
    padding-bottom: 3rem;
    padding-top: 26px;
}

.footerContainer .row2 .pageLinks {
    font-size: 22px;
    font-weight: 700;
    color: white;
    margin-bottom: 32px;
    font-family: "Roboto";
    line-height: 26px;

}

.footerContainer .row2 .socialMedia {
    font-size: 22px;
    font-weight: 700;
    color: #7F7F7F;
    margin-bottom: 32px;
    font-family: "Roboto";
    line-height: 26px;
}

.footerContainer .row2 .contactus {
    font-size: 22px;
    font-weight: 700;
    color: white;
    margin-bottom: 70px;
    font-family: "Roboto";
    line-height: 26px;
}

.footerContainer .row2 .email {
    font-size: 18px;
    font-weight: 500;
    color: #7F7F7F;
    margin-bottom: 10px;
}

.footerContainer .logo {
    font-size: 2rem !important;
    color: white !important;
    font-family: "Roboto" !important;

}

.footerContainer .logo .commune {
    font-family: "blah";
    font-style: italic;
}

.footerContainer .contentOuter {
    width: 30%;
}

.footerContainer .contentPartOne {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    line-height: 142.4%;
    color: #FFFFFF;
}

.footerContainer .contentPartTwo {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: italic;
    font-size: 18px;
    line-height: 142.4%;
    color: #FFFFFF;
}

.footerContainer .pages {
    width: 10%;
}

.footerContainer .socialmedia {
    width: 30%;
}

.footerContainer .contact {
    width: 40%;
}


@media only screen and (min-width:900px) and (max-width:1200px) {

    .footerContainer .row2 .socialMedia {
        font-size: 16px;
        font-weight: 700;
        color: #7F7F7F;
        margin-bottom: 32px;
        font-family: "Roboto";
        line-height: 26px;
    }

    .footerContainer .row2 .contactus {
        font-size: 18px;
        font-weight: 700;
        color: white;
        margin-bottom: 70px;
        font-family: "Roboto";
        line-height: 26px;
    }

    .footerContainer .row2 .email {
        font-size: 14px;
        font-weight: 500;
        color: #7F7F7F;
        margin-bottom: 10px;
    }

    .footerContainer .logo {
        font-size: 1.4rem !important;
        color: white !important;
        font-family: "Roboto" !important;
    }

    .footerContainer .contentPartOne {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 142.4%;
        color: #FFFFFF;
    }

    .footerContainer .contentPartTwo {
        font-family: 'Roboto';
        font-weight: 400;
        font-style: italic;
        font-size: 14px;
        line-height: 142.4%;
        color: #FFFFFF;
    }

    .footerContainer .row2 .pageLinks {
        font-size: 18px;
        font-weight: 700;
        color: white;
        margin-bottom: 32px;
        font-family: "Roboto";
        line-height: 26px;
    }
}

@media only screen and (max-width:900px) {

    .footerContainer {
        min-height: 100vh;
        height: auto;
        min-width: 100vw;

        /* transform: translatey(70px); */
        padding-top: 0rem;
        padding-left: 0rem;

    }

    .footerContainer .logo {

        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: auto;
        transform: translatey(10px);


    }

    .footerContainer .contentOuter {
        width: 80%;
        margin: auto;
        padding: 5px;
        position: relative;
        top: -5px;
        text-align: center;
        justify-content: center;
        align-items: center;


    }

    .footerContainer .contentPartOne {
        font-weight: 500;
        font-size: 16px;

    }

    .footerContainer .contentPartTwo {
        font-weight: 400;
        font-size: 16px;

    }

    .footerContainer .row2 {
        display: flex;
        flex-direction: column;
        gap: 10px;

    }

    .footerContainer .pages {

        width: 80%;
        /* margin: auto; */
        margin-left: 50px;
        position: relative;
        top: -25px;

    }


    .footerContainer .row2 .pageLinks {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;

        line-height: 15px;

    }

    .footerContainer .socialmedia {

        width: 80%;
        /* margin: auto; */
        position: relative;
        top: -25px;
        margin-left: 50px;
        /* border-bottom: 2px solid white; */
    }

    .footerContainer .row2 .socialMedia {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 15px;
    }

    .footerContainer .row2 .contactus {
        font-size: 20px;
        font-weight: 500;

        margin-bottom: 30px;
        line-height: 15px;
    }


    .footerContainer .contact {

        width: 80%;
        /* margin: auto; */
        position: relative;
        top: -25px;
        margin-left: 50px;

    }

    .footerContainer {
        padding-top: 10vh;
    }
}

@media only screen and (min-width:900) and (max-width:1024) {


    .footerContainer {
        padding-top: 3rem;
        padding-left: 0rem;
        border-top: 3px solid white;
        /* margin: auto; */
        height: 100vh;
        width: 100vh;
        /* display: none !important; */
    }

    .footerContainer .row2 {
        display: flex;
        /* flex-direction: column; */
        gap: 50px;
        height: 100vh;
        width: 100vh;
        padding-bottom: 0rem;
        padding-top: 0px;
        margin: auto;
        transform: translateY(40px);
    }

}