@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Questrial&display=swap');

.loginpage {
    background-image: url("../../../public/images/Login.png");
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    width: 100vw;
    height: 100vh;
}

.signinBox,
.signupBox {
    background: rgba(31, 28, 28, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(11px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 16px;
}

.signupBox {
    width: 28vw;
    height: auto;
}

.signinBox {
    width: 28vw;
    height: auto;
}



.signinBox .signInText,
.signupBox .signInText {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #FFFFFF;

}

.signinBox .newUser,
.signupBox .newUser,
.requirements {
    margin: 0;
    padding: 1% 0;
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    line-height: 15px;
    font-family: 'Montserrat';
    text-decoration: none;
    text-transform: none;
}

.signinBox .newUser span,
.signupBox .newUser span,
.requirements {
    font-weight: 400;
    color: #FFFFFF;
}

.signupBox .stepNo {
    font-weight: 550;
}

.signinBox .newUser,
.signupBox .newUser {
    font-weight: 700;
    color: #FF2E64;
}

/* 
 .signinBox .password{
    display: flex;
   
}  */

.requirements {
    font-size: 9px;
    font-weight: 600;
}

.signinBox .continue,
.signupBox .continue {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    margin-top: 20px;
    margin-left: 75%;
    width: 25%;
    height: 25px;
    background: #F74D79;
    border-radius: 16px;
    font-family: 'Montserrat';
    text-transform: none;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;

}

.signinBox .or,
.signupBox .or {
    font-size: 15px;
    font-family: "Montserrat";
    margin-top: 5%;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
}

.signinBox .or div,
.signupBox .or div {
    background-color: white;
    height: 1px;
    width: 45%;
}



.signinBox .submit .loginWith,
.signupBox .submit .loginWith {
    /* Frame 139 */

    /* box-sizing: border-box; */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 30px 10px 10px;
    gap: 10px;
    height: 5vh;
    width: 20vw;
    border: 1px solid #7B7B7B;
    border-radius: 40px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    text-transform: none;

}

.signinBox .submit,
.signupBox .submit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10% 5%;
    flex-direction: column;
    gap: 3vh;
}

.loginpage img {
    height: 10vw;
    width: auto;
}

.signinBox .email ::placeholder,
.signinBox .password ::placeholder {
    text-align: left;
}

.signupBox .registerUsername ::placeholder,
.signupBox .registerEmail ::placeholder,
.signupBox .registerPassword ::placeholder {
    text-align: left;
}



/*****************************************************************************/

@media screen and (max-width:1024px) {

    .signinBox .newUser,
    .signupBox .newUser {
        font-size: 12px;
    }

    .signinBox,
    .signupBox {
        width: 37vw;
    }

    .loginpage img {
        height: 20vw;
    }

    .signinBox .submit .loginWith,
    .signupBox .submit .loginWith {
        justify-content: center !important;
    }
}

@media screen and (max-width:768px) {

    .signinBox,
    .signupBox {
        width: 45vw;
    }

    .signinBox .submit .loginWith,
    .signupBox .submit .loginWith {
        justify-content: center;
        width: 27vw;
    }

    .loginpage img {
        height: 24vw;
    }
}

@media screen and (max-width:426px) {

    .loginpage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .signinBox,
    .signupBox {
        width: 82vw;
    }

    .signinBox .submit .loginWith,
    .signupBox .submit .loginWith {
        justify-content: center;
        width: 60vw;
    }


}