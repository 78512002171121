@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

.card {
    border-radius: 10px;
    background-color: #2F2F2F;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    margin-top: 1;
    border: 1px solid #414141;
}

.cardMedia {
    border-radius: 10px 10px 0px 0px;
    height: 220px
}

.card .details {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 10px;
    padding: 2;
}

.card .cardHeader {
    padding: 0px;
    padding-left: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    color: white;
    font-weight: 500;
}

.card .cardContent3 {
    padding: 20px;
    padding-top: 2px;
    padding-bottom: 11px;
    margin-top: 0px;
}

.card .cardContent3 .titleContainer {
    font-size: 18px;
    color: #F74D79
}

.card .cardContent3 .titleContainer .title {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #F74D79;
    padding-top: 10px;
    text-align: start;
    overflow-wrap: anywhere;
}

.card .cardContent3 .description {
    padding-top: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.08em;
    color: white;
    text-align: start;
}

.card .read_time {
    color: white;
    padding: 4px;
    font-family: 'Questrial', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
}

.card .date {
    color: white;
    padding: 4px;
    font-family: 'Questrial', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
}

.card .tag {
    color: #F74D79;
    background-color: rgba(255, 106, 145, 0.12);
    border-radius: 5px;
    padding: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    font-size: 12px;

}

.card .upvote {
    margin: 5px;
    font-size: 16px;
    position: relative;
    bottom: -2px;
    color: #626262;
}

.content2 {
    padding-left: 16px;
    padding-right: 16px;
}

@media only screen and (max-width:425px) {
    .cardContent3 {
        padding: 5px 10px !important;
    }

    .content2 {
        padding: 0px 15px;
    }
}