@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

.BlogContainerBox {
    max-width: 82%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
}

.mainContainer {
    max-width: 90%;
    width: 90%;
    margin: 0px auto;

}

.tabsContainer {
    background-color: rgba(35, 36, 38, 1);
    border-bottom: 1px solid white;

}

.tabsContainer .tabs {
    font-size: 1.5rem;
    text-transform: none;
    margin-left: 20px;
    margin-right: 20px;
}

.blogCardContainer {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    margin: 0px 10%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.blogCard {
    max-width: 700px;
    width: 350px;
    margin: 3rem 3rem;
    min-height: 435px;
    height: auto;
    flex-wrap: wrap;
}



@media only screen and (min-width:2560px) {
    .mainContainer {
        max-width: 1300px;
    }


}

@media only screen and (max-width:1025px) {}

@media only screen and (max-width:800px) {
    .tabsContainer .tabs {
        font-size: 1.1rem;
    }

    .blogCardContainer {
        margin: 0px 2.5%;

    }

    .blogCard {
        max-width: 300px;
        width: 300px;
        min-width: 300px;
        margin: 3rem 2rem;
        min-height: 435px;
        height: auto;
        flex-wrap: wrap;
    }


}

@media only screen and (max-width:321px) {
    .tabsContainer .tabs {
        font-size: 1rem;
    }

    .blogCardContainer {
        margin: 0px 7%;
    }

    .blogIntroContainer {
        margin: 0px 54px;
    }

    .blogCard {
        min-width: 250px;
        width: 300px;
        margin: 3rem 2rem;
        min-height: 435px;
        height: auto;
        flex-wrap: wrap;
    }
}