  @import url('https://fonts.googleapis.com/css2?family=Cardo&display=swap');

  #layoutContainer {
      padding-top: 50px;
  }


  .ourBlogContainer {
      padding: 10px;
      margin-bottom: 40px !important;
      text-align: center;
      color: white;
      height: 110px;

  }

  .ourBlogContainer .fontContainer1 {
      display: flex;
      height: 110px;
      align-items: center;
      justify-content: center;

  }

  .ourBlogContainer .fontContainer2 {
      display: flex;
      height: 110px;
      position: relative;
      top: -110px;
      align-items: center;
      justify-content: center;
  }

  .ourBlogContainer .font1 {
      font-family: 'Cardo', serif;
      font-size: 3.5rem;
      z-index: 8;
  }

  .ourBlogContainer .font2 {
      font-family: 'Cardo', serif;
      font-size: 5rem;
      font-weight: 900;
      -webkit-text-stroke: 0.1px rgb(255 255 255 / 9%);
      color: #232426;
  }

  .blogIntroContainer {
      margin-bottom: 50px !important;
  }

  .blogIntro {
      font-family: 'Questrial';
      font-style: normal;
      font-weight: 400;
      font-size: 16.5px;
      line-height: 12px;
      color: #ABABAB;
      padding: 5px;
      text-align: center;
  }


  @media only screen and (max-width:800px) {
      #layoutContainer {
          padding-top: 8vh;
      }

      .ourBlogContainer {
          margin-bottom: 30px !important;
      }


  }

  @media only screen and (max-width:430px) {

      .ourBlogContainer {


          margin-bottom: 20px !important;


      }

      .ourBlogContainer .font1 {
          font-size: 2.5rem;
      }

      .ourBlogContainer .font2 {
          font-size: 3.5rem;
      }

      .blogIntroContainer {
          padding: 0px 70px;
      }

      .blogIntro {
          line-height: 18px;
      }
  }