
 .row1{
    background: #2F2F2F;
}

 .label1{
    padding: 10px;
    }

 .whatis{
    color: #636363;
text-align: justify;
font-family: Roboto;
font-size: 20.25px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1.013px;
}

 .collabhous{
    color: #F74D79;
font-family: Roboto;
font-size: 20.25px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1.013px;
}



 .FAQDrop .row2{
    height: fit-content;
align-items: center;
text-align: center;
/* border-radius: 4.5px; */
border-radius: 4.5px solid #3A3A3A;

} 
.FAQDrop{
    background-color: #3A3A3A;
}
  

.FAQDrop .label2{
    color: #B5B5B5;
font-family: Questrial;
font-size: 15.5px;
font-style: normal;
font-weight: 400;
line-height: 19.125px; 
letter-spacing: 0.775px;
padding: 20px;
} 

@media only screen and (min-width:320px ) and (max-width:1023px){

     .row1{
        width: 86vw;
    }
     .whatis , .collabhous{
        font-size: 18px;
    }
    .FAQDrop .label2{
        font-size: 13px;
    }
}




