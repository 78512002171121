.containerCommentArea {
    margin: 0px auto;
    width: 64.26%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 1rem;
}

.typeBox {
    width: 100%;
    margin: 23px auto;
    padding: 14px;
    border-radius: 8px;
    background-color: #2F2F2F;
    border: 1px solid #414141;
    translate: 3px;
}

.typeBox .userDetail {
    display: flex;
    height: 31px;
}

.typeBox .logoUsernameContainer {
    display: flex;
    gap: 14px;
}

.typeBox .userDetail .logo {
    width: 32px;
    height: 32px;

    /* the below 4 lines to be removed */
    color: rgb(250, 250, 250);
    padding: 5px;
    border-radius: 50%;
    background-color: #000000;

}

.typeBox .userDetail .username {

    color: aliceblue;
    font-weight: 500;
    font-size: 13.4px;
    margin: 7.5px 0px;

}

.typeBox .textArea {
    height: auto;
    margin-left: 37px;
    margin-top: 10px;
    color: #A0A0A0;
}

.typeBox #textBox {
    height: auto;
    margin-left: 11px;
    color: #A0A0A0;
    border: none;
    background-color: #2F2F2F;
    padding: 0px;
    width: 90%;
    font-size: 15px;
    font-family: 'Questrial', sans-serif;
    white-space: pre-wrap;
    /* Allow wrapping */
    overflow-wrap: break-word;
    /* This property allows long words to be broken and wrap to the next line */
    word-wrap: break-word;
    /* Additional property for compatibility */
}




#textBox:focus {
    border: none;
    outline: none;
    /* This removes the focus outline, adjust as needed */

}

.typeBox .buttonArea {
    display: flex;
    justify-content: flex-end;
}

.typeBox #respondBtn {
    /* position: absolute; */
    right: 0px;
    width: 85px;
    /* height:23px; */
    color: white;
    padding: 5px 10px;
    border-radius: 18px;
    border: 1px solid white;
    background-color: #2F2F2F;
}

@media only screen and (max-width: 430px) {
    .containerCommentArea {
        width: 90%;
    }

}   