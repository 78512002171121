@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

.replyCard {
    width: 90%;
    margin: 8px 0px;

    padding: 14px;
    border-radius: 8px;
    background-color: #2F2F2F;
    border: 1px solid #414141;
}

.replyCard .userDetail {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 31px;

}

.replyCard .logoUsernameContainer {
    display: flex;
    gap: 14px;
}

.replyCard .userDetail .logo {
    width: 24px;
    height: 24px;

    /* the below 4 lines to be removed */
    color: rgb(13, 138, 210);
    padding: 5px;
    border-radius: 50%;
    background-color: #ffffff;
    font-size: 12px;

}

.replyCard .userDetail .username {

    color: aliceblue;
    font-weight: 500;
    font-size: 11px;
    margin: 7.5px 0px;

}

.replyCard .userDetail .likeCard {
    height: 24px;
    display: flex;
}

.replyCard .userDetail .like {
    height: 24px;
    width: 24px;
    color: white;
}

.replyCard .userDetail .likeCount {
    font-size: 9px;
    margin: auto 0px;
    color: #ffffff;
    font-weight: 500;
}

.replyCard .textArea {
    /* height: 56px; */
    width: 90%;
    margin: 7px auto;
    margin-bottom: 0px;
    translate: 4px;
    line-height: 16px;
    color: #A0A0A0;
    font-size: 12px;
    letter-spacing: 1px;
    font-family: 'Questrial', sans-serif;
}

.replyCard .buttonArea {
    display: flex;
    justify-content: flex-end;
}

.replyCard .replyBtnContainer {
    /* position: absolute; */
    right: 0px;
    width: 120px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.replyCard .replyBtnContainer .reply {
    display: flex;
    align-items: flex-end;
}

.replyCard .replyBtnContainer .icon {
    height: 20px;
    width: 20px;
    color: #616161;
    margin-right: 8px;
}

.replyCard .replyBtnContainer .replyCount {
    font-size: 10px;
    color: #BBBBBB;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}

.replyCard .replyBtnContainer .replyBtn {
    font-size: 10px;
    color: #BBBBBB;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;

}