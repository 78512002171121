.section {
    max-height: 100vh;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blogSection {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

.blogSectionInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
}

.blogHeadings {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
}

.blogsBox {
    display: flex;
    justify-content: space-between;
    height: 76vh;
    width: 100%;
    max-height: 531px;
    max-width: 1200px;
}

.trendingBlog {
    width: 49%;
    height: 100%;
}

.horzBlogsOuter {
    width: 49%;
    height: 100%;
}

.horzBlogsInner {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: auto;
    height: 100%;
}

.heroLandingImage {
    object-fit: cover;
    width: 100vw;
    height: 100vh !important;
    position: absolute;
}

.carouselSection {
    min-height: 100vh;
    width: 42%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carouselContentContainer {
    display: flex;
    width: 95%;
    margin: 15px 0px;
    gap: 15px;
}

.carouselSection .carouselContentBtn {
    border-radius: 30px;
    text-transform: none;
    min-width: 72.6px;
    height: 43.73px;
    border: 0.83px solid rgba(71, 71, 71, 1);




    font-family: 'Roboto';
    font-style: normal;

    font-size: 22px;
    line-height: 27px;
    color: #FFFFFF;

    &:hover {
        background-color: #5f5f5f;

    }
}

.carouselSection .carouselContentBtn:active,
.carouselSection .carouselContentBtn:focus {
    background-color: rgba(247, 77, 121, 1);
}

.carouselSection .carouselContentBtn:focus {
    outline: none;
}

.carouselDisplayText {
    padding: 20px 0px;
    width: 95%;
    margin: 0px auto;





    font-family: 'Questrial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */
    letter-spacing: 0.05em;
    text-align: justify;
    color: #e7e7e7;


    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.whoAreWeContainer {
    display: flex;
    max-height: 100vh;
}

.whoAreWeContainer .contentPanel {
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0px 5%;
}

.whoAreWeContainer .contentPanel>h2 {

    margin-bottom: 20px;
    width: 100%;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-size: 48px;
    line-height: 48px;
    text-align: justify;
    letter-spacing: 0.05em;

    color: #F74D79;


}

.whoAreWeContainer .contentPanel>detail {

    font-family: 'Questrial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;

    letter-spacing: 0.05em;

    color: #e7e7e7;

}

.whoAreWeContainer .imagePanel {
    width: 50%;
}

.whoAreWeContainer .imagePanel>img {
    object-fit: cover;
    width: 100%;
    height: 100vh;
}

.welcomeSection {
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: 20px;
}

.welcomeSection>h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #F74D79;

}

.welcomeSection>detail {
    font-family: 'Questrial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #e7e7e7;


}


.trendingBlogsText {
    color: rgba(247, 77, 121, 1) !important;
    font-size: 2rem !important;
    font-family: "Questrial" !important;
    font-weight: 400;
    line-height: 42px !important;
    letter-spacing: 0.05em !important;
    margin-bottom: 0.4em !important;
}

.moreText {
    width: auto !important;
    color: #b3b3b3 !important;
    font-size: 16px !important;
    display: flex !important;
    align-items: center !important;
    /* padding-right: 40px !important; */
    font-family: "Roboto" !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    letter-spacing: 0.05em !important;
}

@media only screen and (min-width: 320px) and (max-width:900px) {

    .heroLandingImage {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
    }

    .carouselSection {
        min-height: 100vh;
        width: 100vw;
    }

    .carouselContentContainer {
        width: 85%;
        transform: translateY(10px);
        justify-content: center;
    }

    .carouselSection .carouselContentBtn {
        font-size: 12px;
        width: auto;
        height: 32px;
        line-height: 29px;
        min-width: 40px;
    }

    .carouselDisplayText {
        width: 75%;
        font-size: 16px;
        gap: 10px;
        font-weight: 300;
        line-height: 25px;
        text-align: justify;
    }

    .whoAreWeContainer {
        display: flex;
        flex-direction: column;
        gap: 0px;
        max-height: 100vh;
        transform: translateY(40px);
        width: 100%;
    }

    .whoAreWeContainer .contentHeading {
        font-size: 32px;
    }


    .whoAreWeContainer .contentPanel {
        width: 80%;
        justify-content: center;
        align-items: center;


    }

    .whoAreWeContainer .contentPanel>h2 {

        margin-bottom: 10px;
        font-weight: 400;
        font-size: 25px;
        padding: 0px;

    }

    .whoAreWeContainer .contentPanel>detail {
        width: 70%;
        height: fit-content;
        /* margin-left: 20px; */
        padding-top: 50px;

        font-size: 16px;
        line-height: 25px;
        text-align: justify;

    }

    .whoAreWeContainer .imagePanel {
        width: 80%;
        margin: 0px auto;
        height: fit-content;
        padding: 20px;
    }

    .whoAreWeContainer .imagePanel>img {
        object-fit: cover;
        width: 100%;
        height: 30vh;
        margin-bottom: 0px;
    }

    .welcomeSection {

        width: 100%;

        justify-content: center;
        align-items: center;
        text-align: center;

    }

    .welcomeSection>h2 {
        width: 90%;
        font-weight: 300;
        font-size: 32px;
        line-height: 50px;

    }

    .trendingBlogsText {
        font-size: 16px !important;
    }

    .welcomeSection>detail {
        width: 70%;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        line-height: 30px;
    }

    .blogSection {
        max-width: 100vw;
        m-height: 100vh;

        /* overflow: hidden; */
    }

    .blogHeadings {
        margin-top: 35px;
        gap: 5px;
    }

    .trendingBlogsText {
        font-size: 28px !important;
        font-weight: 200;
    }

    .moreText {
        font-size: 15px !important;
        padding: 10px;
        padding-right: 0px !important;
        font-weight: 300 !important;
        text-align: end;
    }

    .blogsBox {
        display: flex;
        flex-direction: column;
        height: auto;
        /* max-height: ; */
        /* transform: translateY(30px);  */
    }

    .trendingBlog {
        width: 100%;
        display: none;
    }

    .blogSectionInner {
        width: 100%;
    }

    .horzBlogsInner {
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        height: 100%;
    }

    .horzBlogsOuter {
        /* display: none; */
        width: 100%;
        /* height: fit-content; */

    }


    #card {
        height: 50vh;
    }

    .connect_footer {
        max-width: 100vw;
        min-height: fit-content;
    }

    .home_footer {
        min-width: 100vw;
        min-height: max-content;
    }


}


@media only screen and (min-width: 900px) and (max-width:1024px) {

    .welcomeSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 20px;
    }

    .welcomeSection>h2 {

        font-weight: 400;
        font-size: 60px;
        line-height: 59px;
    }

    .welcomeSection>detail {
        width: 80%;
        font-family: 'Questrial';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #e7e7e7;

    }


    .carouselSection {
        min-height: 100vh;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .carouselContentContainer {
        width: 85%;
        transform: translateY(10px);
        justify-content: center;
    }

    .carouselSection .carouselContentBtn {
        border-radius: 30px;
        text-transform: none;
        border: 0.83px solid rgba(71, 71, 71, 1);

        width: auto;
        height: 40px;
        line-height: 29px;
        min-width: 40px;


        font-family: 'Roboto';
        font-style: normal;

        font-size: 23.1px;
        line-height: 27px;
        color: #FFFFFF;

        &:hover {
            background-color: #5f5f5f;

        }
    }

    .carouselDisplayText {
        padding: 20px 0px;
        color: rgba(177, 177, 177, 1);
        margin: 0px auto;
        width: 90%;
        font-size: 16px;
        gap: 10px;
        text-align: justify;

        font-family: 'Questrial';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        letter-spacing: 0.05em;

        color: #e7e7e7;


        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    }

}