.carousel {
    width: "95%" !important;
    margin: "0 auto" !important;
    border-radius: "20px" !important;
}

.carouselImage {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 42vw !important;
    height: 25vw !important;

    aspect-ratio: 1 / 2;
    flex-direction: row;
}

.carouselArrows {
    /* display: flex; */
    height: 33.6px;
    width: 33.6px;
    /* padding: 0px 287.3px;  */
    /* justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    /* padding: .4em; */
    /* z-index: 2; */
}

@media only screen and (min-width: 200px) and (max-width:767px) {
    .carouselImage {
        width: 80vw !important;
        height: 48vw !important;

        /* aspect-ratio: 1 / 2; */
    }

}

@media only screen and (min-width: 768px) and (max-width:991px) {
    .carouselImage {
        width: 35vw !important;
        height: 18vw !important;
    }
}

@media only screen and (min-width: 992px) and (max-width:1200px) {
    .carouselImage {
        width: 35vw !important;
        height: 18vw !important;
    }
}