#card {
    border-radius: 10px;
    background-color: #2F2F2F;
    min-width: 100%;
    height: 100%;
    max-height: 100%;
    margin: auto;
    border: 1px solid #414141;
}

#card .imageCard {
    height: 50%;
}

.cardContent {
    height: 50%;
    display: flex;
    flex-direction: column;
    padding: 2;
    margin: 0px 10px;
    margin-top: 16px;
    gap: 10px;
}

#card .cardHeader {
    padding: 10px;
    padding-left: 16px;
    font-size: 13px;
    color: white;
    font-weight: 500;
}

#card .cardContent .blogDetails {
    display: flex;
    align-items: center;
    gap: 6px;
}

#card .cardContent .blogDetails .readT,
.date {
    color: white;
    padding: 4px;
    font-family: 'Questrial';
    font-weight: 400;
    font-size: 14.8978px;
    line-height: 15px;
    letter-spacing: 0.05em;

}


#card .cardContent .blogDetails .tag {
    color: #F74D79;
    background-color: rgba(255, 106, 145, 0.12);
    border-radius: 5px;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 13.4px;
    margin-right: 16px;
    font-family: 'Questrial';
    font-weight: 400;
    font-size: 14.8978px;
    line-height: 15px;
    letter-spacing: 0.05em;

}

#card .cardContent .title {
    color: #F74D79;
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.05em;
    padding-top: 2px;
    overflow-wrap: anywhere;

}

#card .titleCard {
    padding-top: 0;
    padding-bottom: 0;
}

#card .cardContent .upvote {
    margin-left: 5px;
    font-size: 16px;
    position: relative;
    bottom: -2px;
    color: #626262;
}

#card .cardContent .description {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: white;

}

#card .cardContent .avatarIcon {
    width: 29px;
    height: 29px;
}

@media only screen and (min-width : 375px) and (max-width : 769px) {
    #card .cardContent .blogDetails {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
    }

    #card {
        height: 300px;
    }
}

@media only screen and (min-width : 770px) and (max-width : 1200px) {
    #card .cardHeader {
        padding: 10px;
        padding-left: 16px;
        font-size: 10px;
        color: white;
        font-weight: 500;
    }

    #card .cardContent {
        gap: 0px;
    }

    #card .cardContent .avatarIcon {
        width: 21px;
        height: 21px;
    }

    #card .cardContent .username {
        font-size: 12px !important;
    }

    #card .cardContent .blogDetails {
        display: flex;
        align-items: center;
        gap: 9px;
    }

    #card .cardContent .blogDetails .readT,
    .date {
        color: white;
        padding: 4px;
        font-family: 'Questrial';
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.05em;
    }


    #card .cardContent .blogDetails .tag {
        color: #F74D79;
        background-color: rgba(255, 106, 145, 0.12);
        border-radius: 5px;
        padding: 4px;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 10px !important;
        margin-right: 16px;
        font-family: 'Questrial';
        font-weight: 400;
        font-size: 14.8978px;
        line-height: 15px;
        letter-spacing: 0.05em;

    }

    #card .cardContent .avatarIcon {
        width: '20px';
    }

    #card .cardContent .title {
        color: #F74D79;
        font-family: 'Roboto';
        font-weight: 900;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.05em;
        padding-top: 2px;
        overflow-wrap: anywhere;

    }

    #card .titleCard {
        padding-top: 0;
        padding-bottom: 0;
    }

    #card .titleCard .title {
        font-size: 16px;
    }

    #card .cardContent .upvote {
        margin-left: 5px;
        font-size: 12px;
        position: relative;
        bottom: -2px;
        color: #626262;
    }

    #card .cardContent .upvoteIcon {
        width: 20px;
    }

    #card .cardContent .description {
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 0.7rem;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: white;

    }
}