@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');


.appbar {
    width: 68% !important;
    margin-left: auto;
    margin-right: auto;
    top: 10px !important;
    border-radius: 16px;
    max-width: 1500px;
    backdrop-filter: blur(8px);
    /* min-height: 43px; */
}

.toolbar {
    min-height: 50px !important;
    height: 50px;
}

.appbar .tab {
    text-transform: none;
    font-size: 0.8rem;
    margin-left: 16px;
    margin-right: 16px;
    opacity: 1;
    font-family: 'Questrial', sans-serif;
    height: 40px !important;
    font-weight: 600;
}

.logoImageCont {
    display: flex;
    align-items: center;
    justify-content: center;
}

.appbar #avatarContainer {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.appbar #loginBtnContainer .loginBtn {
    /* display: none; */
    color: #F74D79;
    border: 1px solid #F74D79;
    background-color: rgba(255, 95, 95, 0.154);

    background-color: #ff6f6f26;

    margin: 10px;
}


@media only screen and (min-width: 992px) and (max-width:1200px) {
    .appbar {
        width: 75% !important;
        height: 3.3rem !important;
    }

    .appbar .tab {
        margin-left: 6px;
        margin-right: 6px;
        font-size: 0.7rem;
    }
}


@media only screen and (min-width: 768px) and (max-width:991px) {
    .appbar {
        width: 75% !important;
    }

    .appbar .tab {
        display: none;
    }
}

@media only screen and (min-width: 200px) and (max-width:767px) {
    .appbar {
        border: none !important;
        box-shadow: none !important;
        top: 10px !important;
        backdrop-filter: blur(10px);
        width: 90% !important;
        padding: 4px 1%;
        /* height: 8vh !important; */
        /* border-radius: 0px; */
    }

    .appbar .tab {
        display: none;
    }

    #avatarContainer {
        display: none !important;
    }

    .appbar #loginBtnContainer .loginBtn {
        color: #F74D79;
        border: 1px solid #F74D79;
        background-color: rgba(255, 95, 95, 0.154);
        background-color: #ff6f6f26;
        margin: 10px;
        height: 32px;
        width: 60px;
        font-size: 13px;
        margin-right: 0px;
    }
}