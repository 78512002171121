@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

#exploreconnect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    height: auto;
    min-height: 94vh;
}

#exploreconnect .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
    padding: 20px;
    height: auto;
    width: 70%;
}

#exploreconnect .title {
    font-size: 40px;
    color: white;
    margin-bottom: 20px;
    font-weight: 700;
    font-family: 'Questrial', sans-serif;
}

#exploreconnect .options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: auto;
    gap: 20px;
    width: 100%;
}

#exploreconnect .options>div {
    cursor: pointer;
}

#exploreconnect .options .box {
    height: 200px;
    border: 1px solid #414141;
    border-radius: 10px;
    background-color: #2F2F2F;
    padding: 30px;
    width: 30%;
}

#exploreconnect .options .question {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#exploreconnect .options .nTitle {
    height: 50px;
    font-weight: 700;
    font-size: 26px;
    color: white;
    font-family: 'Questrial', sans-serif;
}

#exploreconnect .options .bottomQue {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

#exploreconnect .options .bottomQue .completedQue {
    font-size: 26px;
    font-weight: 900;
    color: #868686;
    display: flex;
}

#exploreconnect .options .bottomQue .outerPinkC {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 124, 158, 0.22);
    border-radius: 50%;
}

#exploreconnect .options .bottomQue .innerPinkC {
    height: 20px;
    width: 20px;
    background-color: #F74D79;
    border-radius: 50%;
}

#exploreconnect .options .follow {
    height: 200px;
}

#exploreconnect .options .iconBox {
    display: flex;
    flex-direction: row;
    gap: 15px;

}

#exploreconnect .options .title {
    height: 200px;

}

@media screen and (max-width: 426px) {
    #exploreconnect .title {
        font-size: 24px;
        margin-bottom: 0px;
        margin-top: 20px;
    }

    #exploreconnect .options {
        flex-direction: column;
    }

    #exploreconnect .options .box {
        height: auto;
        min-height: 200px;
        width: auto;
        min-width: 200px;
        max-width: 200px;
    }
}