@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  top: 2.5em !important;
  right: 1.5em !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100vh;
  width: 100vw !important;
  top: -20px;
  left: -20px;
  min-height: 100vh;
  min-width: 100vw;
  padding: 0em 0em;
  border: 0px solid #000000;
  box-shadow: 0px 0px 50px 50px rgba(13, 13, 13, 0.86);
}

/* General sidebar styles */
.bm-menu {
  background: rgba(13, 13, 13, 0.86);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  /* For Safari */
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #000000;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  /* margin-top: 6rem; */
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Individual item */
.bm-item {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75%;
  text-transform: uppercase;
  text-shadow: 0px 0px 3px aliceblue;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
  color: white;
  text-decoration: none;
  font-family: 'Questrial', sans-serif;
  font-size: 1.5rem;
}

/* Styling of overlay */
.bm-overlay {
  position: relative !important;
  background: none !important;
}

.break-line {
  height: 2px;
  width: 30%;
  border: 1px solid white;
}




/* RESPONSIVENESS */

@media only screen and (min-width: 991px) {
  #outer-container {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  #outer-container {}
}