.section {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.heroBanner {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    height: auto;
    bottom: 5rem;
}

.heroBanner .container {
    width: 80%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: relative;
}

.heroBanner .container>.title {
    font-size: 36px;
    color: #F74D79;
    font-weight: 500;
}

.heroBanner .container .usernameContainer {

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    justify-content: flex-start;
}

.heroBanner .container .usernameContainer>.X {
    font-size: 36px;
    font-weight: 900;
    color: grey;
}

.heroBanner .container .usernameContainer>.username {
    font-size: 22px;
    color: white;
    font-weight: 500;
}

.heroBanner .container .usernameContainer>img {
    width: "36px";
    height: "36px";
    border-radius: "50%";
}

.blogBanner {
    width: 80%;
    margin: 0px auto;
    display: flex;
    justify-content: center;
}

.blogBanner .container {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 6rem;
    margin-bottom: 2rem;
}

.blogBanner .container .title {
    font-size: 36px;
    color: #F74D79;
    font-weight: 500;
}

.blogBanner .container .usernameContainer {

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    justify-content: flex-start;
}

.blogBanner .container .X {
    font-size: 36px;
    font-weight: 900;
    color: grey;
}

.blogBanner .container .username {
    font-size: 22px;
    color: white;
    font-weight: 500;
}

.blogBanner .container>i .usernameContainermg {
    width: "36px";
    height: "36px";
    border-radius: "50%";
}

.blogDetail {
    border-top: 3px solid white;
    border-bottom: 3px solid white;
    padding: 25px 0px;
}

.blogDetail .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.blogDetail .container>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blogDetail .container>div .readT {
    font-size: 23px;
    color: white;
    padding: 4px;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #4B4B4B;
    border-radius: 50%;
}

.blogDetail .container>div .date {
    font-size: 23px;
    color: white;
    padding: 4px;
}

.blogDetail .container>div .tag {
    font-size: 23px;
    color: #F74D79;
    background-color: rgba(255, 106, 145, 0.12);
    border-radius: 5px;
    padding: 4px;
    padding: 0px 8px;
}

.blogContainer {
    width: 90%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
}

.blogContainer .blogContent {
    width: 100%;
    margin: 0px auto;
    display: flex;
    padding: 3rem 0;
}

.blogData {
    max-width: 100%;
    color: white;
    overflow-x: hidden;
}

.blogData img {
    max-width: 100%;
    height: auto;
}

.blogContainer .subtitle {
    color: white;
    padding-top: 40px;
    font-weight: 500;
    font-size: 30px;
    padding-bottom: 1rem;
}

.blogContainer .content {
    color: white;
    padding-bottom: 20px;
    font-size: 20px;
    margin: 20px 0px;
    text-align: justify;
}

.reactSection {
    border-bottom: 3px solid white;
    padding: 5px 0px;
    width: 90%;
    margin: 0px auto;
}

.reactSection .upvote {
    margin: 5px;
    margin-right: 0px;
    font-size: 16px;
    position: relative;
    bottom: -2px;
    color: #626262;
}

.reactSection .sharingBox {
    display: flex;
    align-items: center;
    margin-left: 8px;
    gap: 10px;
}

.commentBox {
    margin: 0px auto;
    padding: 0px 0px;
    width: 90%;
}

.relatedBlogsSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 33px auto;
}

.relatedBlogsSection .title {
    font-size: 28px;
    color: rgb(255, 255, 255);
}

.relatedBlogsSection .more {
    color: #b3b3b3 !important;
    font-size: 16px !important;
    font-family: "Roboto" !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    letter-spacing: 0.05em !important;
}

.relatedBlogsCardContainer {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 11 */
}

.relatedBlogsCardContainer::-webkit-scrollbar {
    display: none;
    /* WebKit browsers (Chrome, Safari) */
}

.relatedBlogCard {
    max-width: 350px;
    width: 350px;
    margin: 3rem 2rem;
    height: 420px;
    position: relative;
}



@media only screen and (max-width:800px) {
    .blogContainer .blogContent {
        width: 100%;
        margin: 0px auto;
        display: flex;
        flex-wrap: wrap;
    }

    .blogContainer .blogContent>.image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

@media only screen and (max-width:430px) {
    .heroBanner {
        height: auto;
    }

    .heroBanner .container {
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        padding: 10vw;
    }

    .heroBanner .container .usernameContainer {

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        justify-content: flex-start;
    }

    .heroBanner .container .title {
        font-size: 28px;
    }

    .blogBanner {
        height: auto;
        width: 90%;
    }

    .blogBanner .container {
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        padding: 10vw;
    }

    .blogBanner .container>.title {
        font-size: 28px;
    }

    .blogBanner .container .usernameContainer {

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        justify-content: flex-start;
    }

    .blogDetail .container>div .readT {
        font-size: 16px;
        color: white;
        padding: 4px;
    }

    .dot {
        height: 6px;
        width: 6px;
        background-color: #4B4B4B;
        border-radius: 50%;
    }

    .blogDetail .container>div .date {
        font-size: 16px;
        color: white;
        padding: 4px;
    }

    .blogDetail .container>div .tag {
        font-size: 16px;
        color: #F74D79;
        background-color: rgba(255, 106, 145, 0.12);
        border-radius: 5px;
        padding: 4px;
        padding: 0px 8px;
    }

    .blogContainer .subtitle {
        margin: 0px 40px;
    }

    .blogContainer .content {
        margin: 20px 40px;
        text-align: justify;
    }

    .relatedBlogsSection {
        width: 86%;
        align-items: end;
    }

    .relatedBlogsSection .title {
        font-size: 30px;
    }

    .relatedBlogsSection .more {
        font-size: 18px;
    }
}