#account-menu {}

#account-menu .dropAvatar {
    color: rgb(255, 255, 255);
}

.notification-list {
    max-height: 60vh;
    overflow-y: auto;
}

.notification-list::-webkit-scrollbar {
    width: 10px;
}

.notification-list::-webkit-scrollbar-track {
    background: #888888ce;
}

.notification-list::-webkit-scrollbar-thumb {
    background: #424242c1;
}

.notification-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}