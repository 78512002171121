@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

.commentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.commentCard {
    width: 100%;
    margin: 8px auto;

    padding: 14px;
    border-radius: 8px;
    background-color: #2F2F2F;
    border: 1px solid #414141;
}

.commentCard .userDetail {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 31px;

}

.commentCard .logoUsernameContainer {
    display: flex;
    gap: 14px;
}

.commentCard .userDetail .logo {
    width: 32px;
    height: 32px;

    /* the below 4 lines to be removed */
    color: rgb(13, 138, 210);
    padding: 5px;
    border-radius: 50%;
    background-color: #ffffff;

}

.commentCard .userDetail .username {

    color: aliceblue;
    font-weight: 500;
    font-size: 13.4px;
    margin: 7.5px 0px;

}

.commentCard .userDetail .likeCard {
    height: 24px;
    display: flex;
}

.commentCard .userDetail .like {
    height: 24px;
    width: 24px;
    color: white;
}

.commentCard .userDetail .likeCount {
    font-size: 9px;
    margin: auto 0px;
    color: #ffffff;
    font-weight: 500;
}

.commentCard .textArea {
    height: auto;
    width: 90%;
    margin: 7px auto;
    margin-bottom: 0px;
    translate: 4px;
    line-height: 16px;
    color: #A0A0A0;
    font-size: 14px;
    letter-spacing: 1px;
    font-family: 'Questrial', sans-serif;
}

.commentCard .buttonArea {
    display: flex;
    justify-content: flex-end;
}

.commentCard .replyBtnContainer {
    /* position: absolute; */
    right: 0px;
    width: 120px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.commentCard .replyBtnContainer .reply {
    display: flex;
    align-items: flex-end;
}

.commentCard .replyBtnContainer .icon {
    height: 20px;
    width: 20px;
    color: #616161;
    margin-right: 8px;
}

.commentCard .replyBtnContainer .replyCount {
    font-size: 10px;
    color: #BBBBBB;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}

.commentCard .replyBtnContainer .replyBtn {
    font-size: 10px;
    color: #BBBBBB;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;

}




/* .container {
    margin: 0px auto;
    width: 64.26%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
} */

.replyBox {
    width: 90%;
    margin: 23px 0px;
    padding: 14px;
    border-radius: 8px;
    background-color: #2F2F2F;
    border: 1px solid #414141;
}

.replyBox .userDetail {
    display: flex;
    height: 31px;
}

.replyBox .logoUsernameContainer {
    display: flex;
    gap: 14px;
}

.replyBox .userDetail .logo {
    width: 32px;
    height: 32px;

    /* the below 4 lines to be removed */
    color: rgb(250, 250, 250);
    padding: 5px;
    border-radius: 50%;
    background-color: #000000;

}

.replyBox .userDetail .username {

    color: aliceblue;
    font-weight: 500;
    font-size: 13.4px;
    margin: 7.5px 0px;

}

.replyBox .textArea {
    height: auto;
    margin-left: 37px;
    margin-top: 10px;
    color: #A0A0A0;
}

.replyBox #textBox {
    /* height: 56px; */
    margin-left: 11px;
    color: #A0A0A0;
    border: none;
    background-color: #2F2F2F;
    padding: 0px;
    width: 90%;
    font-size: 15px;
    font-family: 'Questrial', sans-serif;
    white-space: pre-wrap;
    /* Allow wrapping */
    overflow-wrap: break-word;
    /* This property allows long words to be broken and wrap to the next line */
    word-wrap: break-word;
    /* Additional property for compatibility */
}




#textBox:focus {
    border: none;
    outline: none;
    /* This removes the focus outline, adjust as needed */

}

.replyBox .buttonArea {
    display: flex;
    justify-content: flex-end;
}

.replyBox #respondBtn {
    /* position: absolute; */
    right: 0px;
    width: 85px;
    /* height:23px; */
    color: white;
    padding: 5px 10px;
    border-radius: 18px;
    border: 1px solid white;
    background-color: #2F2F2F;
}



@media only screen and (max-width:430px) {
    .commentCard .textArea {
        height: auto;
        width: 60%;
    }

    .commentCard .buttonArea {
        padding-top: 10px;
    }

    .typeBox #textBox {
        width: 60%;
    }

    .typeBox #respondBtn {
        margin-top: 20px;
    }
}