#cardContainer {
    height: auto;
    max-height: 32%;
    background-color: #2F2F2F;
    min-width: 100%;
    border: 1px solid #414141;
}

#cardContainer .imageContainer {
    display: block;
    width: 48%;
}

#cardContainer .contentContainer {
    width: 50%;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#cardContainer .cardContainerInner {
    height: 100%;
}

#cardContainer .avatar {
    background-color: red;
    height: 24px;
    width: 24px;
    font-size: 12px;
}

#cardContainer .cardHeader {
    padding: 5px;
    padding-left: 16px;
    font-size: 12px;
    color: white;
    font-weight: 500;
}

#cardContainer .title {
    font-size: 16px;
    color: #F74D79;
    padding: 0px 18px;

    font-family: "Roboto";
    font-weight: 700;

    line-height: 21px;
    letter-spacing: 0.05em;

}

#cardContainer .blogDetails {
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
}

#cardContainer .blogDetails>div {
    display: flex;
    align-items: center;
    padding-top: 1vh;
    padding-left: 0p;
}

#cardContainer .blogDetails>div .readT {
    color: white;
    padding: 4px;
    font-size: 10px;
    font-family: "Questrial";
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-align: center;
}

#cardContainer .blogDetails>div .date {
    color: white;
    padding: 4px;
    font-size: 10px;
    text-align: center;
}

#cardContainer .blogDetails>div .tag {
    color: #F74D79;
    background-color: rgba(255, 106, 145, 0.12);
    border-radius: 5px;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 10px;
    margin-left: 5px;
    text-align: center;
}

#cardContainer .cardActions {
    padding-top: 0;
    padding-left: 10px;
    padding-bottom: 0px;
}

#cardContainer .upvote {
    margin-left: 5px;
    font-size: 16px;
    position: relative;
    bottom: -2px;
    color: #626262;
}

#cardContainer .upvoteIcon {
    width: 24px;
}

@media only screen and (min-width: 320px) and (max-width:900px) {

    #cardContainer {
        height: 165px;
        background-color: #2F2F2F;
        min-width: 100%;
        min-height: 50px;
        max-height: 150px;
        height: 165px;
        border: 1px solid #414141;
        margin: 0px;
    }

    #cardContainer .blogDetails {
        padding-left: 0px;
        display: flex;
        justify-content: space-between;
    }

    #cardContainer .blogDetails>div {
        padding-left: 16px;
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }


    #cardContainer .blogDetails>div .readT {
        padding: 0px;
        font-size: 11px;
        line-height: 10px;
        width: auto;
    }

    #cardContainer .blogDetails>div .date {
        padding: 0px;
        font-size: 11px;
        line-height: 10px;
        width: auto;
    }

    #cardContainer .blogDetails>div .tag {
        font-size: 11px;
        padding: 0px;
        width: auto;
    }

}

@media only screen and (min-width: 900px) and (max-width:1025px) {
    #cardContainer {
        height: 165px;
        background-color: #2F2F2F;
        min-width: 100%;
        min-height: 50px;
        max-height: 150px;
        height: 165px;
        border: 1px solid #414141;
        margin: 0px;
    }

    #cardContainer .blogDetails>div {
        padding: 0px;
    }

    #cardContainer .blogDetails>div .readT {
        padding: 0px 4px;
        font-size: 0.6rem;
    }

    #cardContainer .blogDetails>div .date {
        margin-bottom: 0px;
        margin: 0px;
        padding: 0px 4px;
        font-size: 0.6rem;
    }

    #cardContainer .blogDetails>div .tag {

        font-size: 0.5rem;
    }

    #cardContainer .avatar {
        background-color: red;
        height: 20px;
        width: 20px;
        font-size: 12px;
    }

    #cardContainer .upvoteIcon {
        width: 18px;
    }

    #cardContainer .upvote {
        margin-left: 5px;
        font-size: 14px;
        position: relative;
        bottom: -2px;
        color: #626262;
    }

    #cardContainer .cardHeader {
        padding: 5px;
        padding-top: 2px;
        padding-left: 16px;
        color: white;
        font-weight: 500;
    }

    #cardContainer .title {
        font-size: 0.8rem;
        line-height: 0.8rem;
    }

    #cardContainer .imageContainer {
        display: block;
        width: 42%;
        font-size: 0.6rem;
    }

    #cardContainer .contentContainer {
        width: 58%;
        padding-top: 5px;
    }

    #cardContainer .cardActions {
        padding-top: 0px;
    }

    #cardContainer .blogDetails>div {
        padding-top: 4px;
    }

    #cardContainer .blogDetails>div .date {
        margin-bottom: 0px;
    }
}