@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Questrial&display=swap');

* {
  margin: 0;
  padding: 0;
  padding-top: 0;
  box-sizing: border-box;
}

#root {
  height: 100%;
  min-height: 100vh;
  /* width: 100vw; */
}

a :hover {
  cursor: pointer;
}


body {
  font-family: "roboto", sans-serif;
}
/* For Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #232426; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  box-shadow: 0px 0px 10px 20px #f2f2f298;
  background: #f2f2f298; /* color of the scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff00; /* color of the scroll thumb on hover */
}