.questionsPage .qsection {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.questionsPage .section1 {

    gap: 30px;
}

.questionsPage .qsection .circle {
    height: 105px;
    width: 105px;
    border-radius: 50%;
    background-color: #2F2F2F;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
}

.questionsPage .qsection .circle .type {
    font-size: 22px;
    color: white;
}

.questionsPage .qsection .titleContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 20px;
}

.questionsPage .qsection .titleContainer .title {
    font-size: 48px;
    font-weight: 700;
    color: white;
    text-align: center;
}

.questionsPage .qsection .titleContainer .subtitle {
    font-size: 20px;
    font-weight: 300;
    color: #B0B0B0;
    text-align: center;
}

.questionsPage .qsection .letsGoBtn {
    height: 50px;
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #F74D79;
    border: none;
    padding: 20px 20px;
    border-radius: 10px;
    color: aliceblue;
    font-weight: 700;
    cursor: pointer;
}

.questionsPage .qsection .nextBtn {
    height: 50px;
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #F74D79;
    border: none;
    padding: 20px 20px;
    border-radius: 10px;
    color: aliceblue;
    font-weight: 700;
    margin-top: 15px;
    cursor: pointer;
}

.questionsPage .qsection .title {
    font-size: 28px;
    color: white;
    font-weight: 500;
    margin-bottom: 15px;
}

.questionsPage .section2>div,
.section3>div,
.section4>div {
    width: 50%;
}

.questionsPage .section2 .form {
    display: flex;
    flex-direction: column;
}


.questionsPage .section2 .inputBox {
    height: 56px;
    font-size: 18px;
    margin: 15px 0px;
    background-color: #343434;
    border: 1px solid #5E5E5E;
    border-radius: 8px;
    color: #A0A0A0;
    padding: 20px;
    font-family: 'Questrial', sans-serif;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: left;
}

.questionsPage .section3 .subtitle {
    font-size: 18px;
    color: #A7A7A7;
    margin-bottom: 15px;
}

.questionsPage .section3 .input_Box {
    height: auto;
    min-height: 206px;
    width: 100%;
    margin: 15px 0px;
    background-color: #343434;
    border: 1px solid #5E5E5E;
    border-radius: 8px;
    font-size: 18px;
    color: #A0A0A0;
    padding: 35px;
    font-family: 'Questrial', sans-serif;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: left;
}

.questionsPage .section4>div {

    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 20px;
}

.section4 .fileupload {
    height: 67px;
    width: 763px;
}


.questionsPage .section5 {
    padding: 0px 10%;
    gap: 30px;
}

.questionsPage .section5>.titleC {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 20px; */
}

.questionsPage .section5 .subtitle {
    font-size: 18px;
    color: #A7A7A7;
    margin-bottom: 15px;
    padding: 0px 20%;
    text-align: center;
}

.questionsPage .section5 .form {
    width: 80%;
    display: flex;
    justify-content: space-between;

}

.questionsPage .section5 .inputBox {
    background-color: #2F2F2F;
    border: 1px solid #414141;
    font-size: 20px;
    color: #939393;
    height: 62px;
    width: 29%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 25px;
}

.questionsPage .section5 .btn {
    width: 220px;
}



/**********************************************************************************************************/


@media screen and (max-width:426px) {

    .questionsPage .section1 .titleContainer,
    .questionsPage .section2>div,
    .questionsPage .section3>div,
    .questionsPage .section4>div,
    .questionsPage .section5>div {
        width: 90vw;
    }

    .questionsPage .section5 .titleC .subtitle {
        text-align: start;
        padding: 0;
    }

    .questionsPage .section5 .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90vw;
        gap: 10vw;

    }

    .questionsPage .section5 .form .inputBox {
        width: 100%;

    }
}